import React, { Component,Fragment } from 'react';
import ParticipantVideo from '../ParticipantVideo'

const TEAMMATE_WIDTH = '105px'
const TEAMMATE_HEIGHT = '79px'

class TeamView extends Component {

  participantPlaceholder(athlete) {
    return(
      <div key={"participantPlaceholder_"+athlete.name+athlete.seat} style={{width: TEAMMATE_WIDTH, height: TEAMMATE_HEIGHT, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '1px solid gray', backgroundColor: 'rgba(0,0,0,0.5)'}}>
        <span style={{fontSize: 14}}>Seat {athlete.seat}</span>
        <span style={{fontSize: 14}}>{athlete.name}</span>
      </div>
    )
  }

  render() {

    const { room,participant,eventData,changeParticipant } = this.props

    const participantIdentity = JSON.parse(participant.identity)
    const thisBoat = eventData.boats.find((boat) => boat.lane === participantIdentity.lane)

    // map remote video participants
    let participantLayout = {}
    for (const testParticipant of room.participants.values()) {
      let identity = JSON.parse(testParticipant.identity)
      if (identity.role === "athlete") {
        if (!participantLayout[identity.lane])
          participantLayout[identity.lane] = {}
        participantLayout[identity.lane][identity.seat] = testParticipant
      }
    }

    let teamParticipants = []
    for (let x = 1; x <= eventData.seats; x++) {
      // don't include participant in team
      if (participantIdentity.seat !== x) {
        if (participantLayout[participantIdentity.lane] && participantLayout[participantIdentity.lane][x])
          teamParticipants.push(<div key={'participantVideoWrapper_'+participantLayout[participantIdentity.lane][x].sid} style={changeParticipant ? {cursor: 'pointer'} : {}} onClick={changeParticipant ? () => changeParticipant(participantLayout[participantIdentity.lane][x]) : () => {}}><ParticipantVideo width={TEAMMATE_WIDTH} height={TEAMMATE_HEIGHT} key={'participantVideo_'+participantLayout[participantIdentity.lane][x].sid} participant={participantLayout[participantIdentity.lane][x]} /></div>)
        else
          teamParticipants.push(this.participantPlaceholder(thisBoat.athletes[x-1]))
      }
    }

    return(

      <Fragment>
        {teamParticipants}
      </Fragment>

    )
  }

}

export default TeamView