import React, { Component} from 'react';
import RCStackLogo from '../../assets/images/Regatta_Central_Logo_STACK_Rev.png'

class Toolbar extends Component {

  constructor(props) {
    super(props)
    this.state = {
    	showMsgInput: false
    }
    this.sendModeratorMessageNow = this.sendModeratorMessageNow.bind(this)
  }

  sendModeratorMessageNow() {
	  console.log('sending message')
	  this.props.emitSocketAction({action:'moderatorMessage', data: 'test message'})
	  this.setState({msg: false})
  }

  render() {
    const { showBio, showChart, showRaceModal, showAthleteModal, showAnnouncers, timer } = this.props
    const { showMsgInput } = this.state

    return(

      <div style={{width: '100%', height: 40, backgroundColor: 'black', display: 'flex', flexDirection: 'row', alignItems: 'left', fontSize: '1rem'}}>
        <img src={RCStackLogo} alt="RegattaCentral" style={{height: 36, padding: 2}} />
        <input type="button" onClick={() => showAthleteModal()} style={{margin: '5px 10px'}} value={ 'Athletes' }/>
        <input type="button" onClick={() => this.props.toggleDisplayBio()} style={{margin: '5px 10px'}} value={ showBio ? 'Hide Bio' : 'Show Bio'}/>
        <input type="button" onClick={() => this.props.toggleDisplayChart()} style={{margin: '5px 10px'}} value={ showChart ? 'Hide Chart' : 'Show Chart'}/>
        <input type="button" onClick={() => this.props.toggleDisplayAnnouncers()} style={{margin: '5px 10px'}} value={ showAnnouncers ? 'Hide Announcers' : 'Show Announcers'}/>
        {/*<Button variant='warning' onClick={() => this.setState({showMsgInput: true})} style={{margin: '5px 10px'}}>Send Message to Announcers</Button>*/}
        <input type="button" onClick={() => this.props.timerToggle()} style={{margin: '5px 10px'}} value={timer.running ? 'Stop Clock' : 'Start Clock'} />
        <input type="button" onClick={() => showRaceModal()} style={{margin: '5px 10px'}} value={'Set Race'} />
        { showMsgInput &&
        	<div style={{backgroundColor:'blue'}}>
              <input type="text" onChange={() => this.sendModeratorMessageNow()} />
            </div>
        }
      </div>
    )
  }

}

export default Toolbar