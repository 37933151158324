import React, { Component } from 'react';
import ParticipantVideo from '../ParticipantVideo'
import Toolbar from '../Toolbar'
import AllAthletes from './AllAthletes';
import RowersChoiceLogo from '../../assets/images/rowers_choice_logo.png'
import RCStackLogo from '../../assets/images/Regatta_Central_Logo_STACK_Rev.png'
import moment from 'moment'
import TimerBeep from '../../assets/images/beep-08b.wav'
import TimerBeep2 from '../../assets/images/beep-02.wav'
import { Bar } from 'react-chartjs-2'
import { Modal } from 'react-bootstrap'

const BEEP = new Audio(TimerBeep)
const BEEP2 = new Audio(TimerBeep2)

class ModeratorRoom extends Component {

  constructor(props) {
    super(props)
    this.chartContainerRef = React.createRef()

    this.state = {
      room: this.props.room,
      timer: {
      	prelude : {
            color: 'red',
            length: 10,
            direction: -1,
            beep: true,
            beep2: false
      	},
      	race: {
            color: 'green',
            length: 2000,
            direction: 1,
            beep: false,
            beep2: false
      	},
        beep: true,
        beep2: false,
        display: 'Ready',
        racing: false,
        running: false,
        color: 'red',
        time: 0
      },
      chartData: {},
      participants: this.props.room.participants,
      participantsWithData: [],
      lanesWithData: [],
      localParticipantRole: JSON.parse(this.props.room.localParticipant.identity).role,
      focusParticipant: null,
      focusData: null,
      showBio: false,
      showChart: false,
      showRaceModal: false,
      showAthleteModal: false,
      showAnnouncers: true,
      dataUpdates:0,
      logo: this.props.eventData.imageUrl,
	  }

    if (!this.state.logo) {
      this.setState({logo : RowersChoiceLogo })
    }

    this.setFocusParticipant = this.setFocusParticipant.bind(this)
    this.setFocusData = this.setFocusData.bind(this)
    this.moderatorControlCallback = this.moderatorControlCallback.bind(this)
    this.emitSocketAction = this.emitSocketAction.bind(this)
    this.moderatorAVControl = this.moderatorAVControl.bind(this)
    this.updateBarChart = this.updateBarChart.bind(this)
    this.updateErgData = this.updateErgData.bind(this)
    this.toggleDisplayBio = this.toggleDisplayBio.bind(this)
    this.toggleDisplayChart = this.toggleDisplayChart.bind(this)
    this.toggleDisplayAnnouncers = this.toggleDisplayAnnouncers.bind(this)
    this.setTimer = this.setTimer.bind(this)
    this.timerToggle = this.timerToggle.bind(this)
    this.handleOpenRaceModal = this.handleOpenRaceModal.bind(this);
    this.handleCloseRaceModal = this.handleCloseRaceModal.bind(this);
    this.handleOpenAthleteModal = this.handleOpenAthleteModal.bind(this);
    this.handleCloseAthleteModal = this.handleCloseAthleteModal.bind(this);
    this.handlePreludeLengthChange = this.handlePreludeLengthChange.bind(this)
    this.handleRaceLengthChange = this.handleRaceLengthChange.bind(this)
  }

  componentDidMount() {

    const { room } = this.state

    // console current room state
    room.participants.forEach(participant => {
      let identity = JSON.parse(participant.identity)
      console.log("Already in Room: " + identity.name + " - role: " + identity.role);
    });

    // set up event listeners for room

    // Participant joining room
    room.on('participantConnected', participant => {
      let identity = JSON.parse(participant.identity)
      console.log("Joining: " + identity.name + " - role: " + identity.role);
      this.setState({participants: room.participants})
    });

    room.on('participantDisconnected', participant => {
      let identity = JSON.parse(participant.identity)
      console.log("Disconnected: " + identity.name + " - role: " + identity.role);
      this.setState({participants: room.participants})
    })

    // console output just to listen/debug room events

    /*
    room.on('trackPublished', (publication, participant) => {
      console.log('trackPublished')
      console.log(publication)
      this.setState({participants: room.participants})
    })

    room.on('trackUnpublished', (publication, participant) => {
      console.log('trackUnpublished')
      console.log(publication)
    })

    room.on('trackSubscribed', (track,publication,participant) => {
      console.log('trackSubscribed')
      console.log(publication)
    })

    room.on('trackUnsubscribed', (track, publication, participant) => {
      let identity = JSON.parse(participant.identity)
      console.log(identity.name + ' removed track: ' + track.kind);
    });
    */


  }

  emitSocketAction(actionObj) {
    const { room } = this.state
    room.localParticipant.tracks.forEach(publication => {
      if (publication.kind === "data")
        publication.track.send(JSON.stringify({...actionObj,source: room.localParticipant.sid}))
    })
  }

  moderatorControlCallback(data) {
    const dataObj = JSON.parse(data)

    const { participants, localParticipantRole } = this.state
    // verify source is moderator
    if (dataObj.source) {
      const participant = dataObj.source ? participants.get(dataObj.source) : '{}'
      const identity = JSON.parse(participant.identity)
      if (identity.role !== "moderator" && identity.role !== 'producer')
        return
    }

    switch(dataObj.action) {
      case 'setFocusParticipant':
        const participant = dataObj.data ? participants.get(dataObj.data) : null
        this.setFocusParticipant(participant,true)
        break
      case 'setFocusData':
        this.setFocusData(dataObj.data, true)
        break
      case 'setTimer':
        const timer = dataObj.data
        if (localParticipantRole !== "moderator" && localParticipantRole !== 'producer') {
      	  if(timer.beep)
      	    BEEP.play()
      	  if(timer.beep2)
      	    BEEP2.play()
        }
        this.setState({timer:timer})
        break
      case 'toggleBio':
        this.setState({showBio: dataObj.data})
    	break
      case 'toggleChart':
        this.setState({showChart: dataObj.data})
    	break
      case 'toggleAnnouncers':
        this.setState({showAnnouncers: dataObj.data})
    	break
      case 'moderatorMessage':
        console.log(dataObj.data)
    	break
      default:
    }
  }

  handleOpenRaceModal () {
	this.setState({ showRaceModal: true });
  }

  handleCloseRaceModal () {
    this.setState({ showRaceModal: false });
  }

  handleOpenAthleteModal () {
    this.setState({ showAthleteModal: true });
  }

  handleCloseAthleteModal () {
    this.setState({ showAthleteModal: false });
  }


  toggleDisplayBio() {
    this.setState({showBio: !this.state.showBio}, () => { this.emitSocketAction({action: 'toggleBio', data: this.state.showBio})})
  }

  toggleDisplayChart() {
    this.setState({showChart: !this.state.showChart}, () => { this.emitSocketAction({action: 'toggleChart', data: this.state.showChart})})
  }

  toggleDisplayAnnouncers() {
    this.setState({showAnnouncers: !this.state.showAnnouncers}, () => { this.emitSocketAction({action: 'toggleAnnouncers', data: this.state.showAnnouncers})})
  }

  setTimer(timer) {
    this.setState({timer: timer}, () => { this.emitSocketAction({action: 'setTimer', data: this.state.timer})})
  }

  handlePreludeLengthChange(event) {
    const val = event.target.value.replace(/\+|-/ig, '')
    if (!val || val === '0') return
    let timer = this.state.timer
    timer.prelude.length = parseInt(val)
    this.setTimer(timer)
  }

  handleRaceLengthChange(event) {
    const val = event.target.value.replace(/\+|-/ig, '')
	  if (!val || val === '0') return
		let timer = this.state.timer
		timer.race.length = parseInt(val)
		this.setTimer(timer)
  }

  handleRaceFormatChange(event) {
    const val = event.target.value
		let timer = this.state.timer
		timer.race.direction = parseInt(val)
		this.setTimer(timer)
  }

  setTimerValues(values) {
    let timer = this.state.timer
    timer.color = values.color
    timer.direction = values.direction
    timer.beep = values.beep
    timer.beep2 = values.beep2
    if (timer.direction === 1) {
      timer.time = 0
    } else {
      timer.time = values.length
    }
    return timer
  }

  timerToggle() {
  	let timer = this.state.timer
  	timer.running = !timer.running
  	if (timer.running) {
  	  timer = this.setTimerValues(timer.prelude)
  	  timer.racing = false
  	  timer.display = 'Ready'

  	  this.interval = setInterval(() => {
  	    if (timer.racing && timer.time < 0) {
  	      clearInterval(this.interval)
  	      return
  	    }
  	    if (timer.time !== 0) {
    		  timer.display = moment.utc(timer.time*1000).format("mm:ss")
    		  timer.beep2 = false
  	    } else {
  	      timer = this.setTimerValues(timer.race)
    		  if (timer.racing) {
    		    timer.display = '00:00'
    		    timer.color = 'orange'
    		    clearInterval(this.interval)
    		    timer.racing = false
    		    timer.running = false
            timer.beep2 = false
    		  } else {
    		    timer.display = 'GO'
            timer.beep2 = true
    		  }
    		  timer.racing = true
    		  timer.beep = false
    	  }
        timer.time = timer.time + timer.direction
        this.setState({ timer: timer }, () => { this.emitSocketAction({action: 'setTimer', data: timer})})
      }, 1000)
  	} else {
  	  clearInterval(this.interval)
  	  timer.color = 'orange'
  	  this.setState({ timer: timer}, () => { this.emitSocketAction({action: 'setTimer', data: timer})})
  	}
  }

  moderatorAVControl(data) {
    if (data.hasOwnProperty('audioMuted')) {
      if (data.audioMuted === true) {
        const messageObj = {
          action: 'muteParticipantAudio',
          data: data.participant
        }
        this.emitSocketAction(messageObj)
      }
      if (data.audioMuted === false) {
        const messageObj = {
          action: 'unmuteParticipantAudio',
          data: data.participant
        }
        this.emitSocketAction(messageObj)
      }
    }
    if (data.hasOwnProperty('videoMuted')) {
      if (data.videoMuted === true) {
        const messageObj = {
          action: 'muteParticipantVideo',
          data: data.participant
        }
        this.emitSocketAction(messageObj)
      }
      if (data.videoMuted === false) {
        const messageObj = {
          action: 'unmuteParticipantVideo',
          data: data.participant
        }
        this.emitSocketAction(messageObj)
      }
    }

  }

  setFocusParticipant(participant,remote=false){

	  console.log('setFocusParticipant ' + remote)
    this.setState({focusParticipant: participant})

    // emit action to moderator listeners if fired by local participant
    if (!remote) {
      const messageObj = {
        action: 'setFocusParticipant',
        data: participant ? participant.sid : null
      }
      this.emitSocketAction(messageObj)
    }
  }

  setFocusData(focusState, remote=false){

    /*
  	const barHeight = focusState ? FOCUS_PARTICIPANT_HEIGHT : CHART_HEIGHT
  	const barWidth = focusState ? FOCUS_PARTICIPANT_WIDTH : CHART_WIDTH

    let dataPoints = this.chart.options.data[0].dataPoints
    let chart = {
  	  axisYType: "secondary",
  	  animationEnabled: true,
  	  theme: 'light1',
  	  height: barHeight,
  	  width: barWidth,
  	  title:{
  	    text: 'Meters'
  	  },
  	  axisX: {
	    margin: 10,
		labelPlacement: "inside",
		tickPlacement: "inside"
	  },
	  axisY2: {
		includeZero: true,
		suffix: "m"
	  },
	  type: "bar",
  	  data: [{
  	    type: "bar",
  	    axisYType: "secondary",
  	    yValueFormatString: "#,###m",
  	    indexLabel: "{y}",
  		dataPoints: dataPoints
  	  }]
  	}
  	this.setState({chart: chart})
  	this.setState({focusData: focusState})
    */

  	// emit action to moderator listeners if fired by local participant
  	if (!remote) {
  	  const messageObj = {
  	    action: 'setFocusData',
  	    data: focusState
  	  }
  	  this.emitSocketAction(messageObj)
  	}
  }

  updateErgData(data) {

    const socketData = JSON.parse(data)
    const { participantsWithData } = this.state
    var newParticipants = [...participantsWithData]

    const source = socketData.source
    const stateKey = "chart_data_"+source
    var updatedData = {}

    // init source key in state
    if (!this.state[stateKey]) {
      let max = 255
      let min = 0
      let r = Math.floor(Math.random() * (max - min + 1)) + min
      let g = Math.floor(Math.random() * (max - min + 1)) + min
      let b = Math.floor(Math.random() * (max - min + 1)) + min
      updatedData.color = {r, g, b}
      newParticipants.push(source)
    } else {
      updatedData = this.state[stateKey]
    }

    updatedData = {...updatedData, stats: socketData.stats}

    this.setState({[stateKey]: updatedData, participantsWithData: newParticipants}, () => this.updateBarChart())

  }

  updateBarChart() {

    const { participantsWithData } = this.state

    var maxDistance = null
    var minDistance = null
    var laneValues = {}

    // total up team data
    for (const participantID of participantsWithData) {
      const stateKey = "chart_data_"+participantID
      const data = this.state[stateKey]
      const lane = data.stats.lane
      if (!laneValues[lane]) {
        laneValues[lane] = {distances: [data.stats.distance], color: data.color}
      } else {
        laneValues[lane].distances.push(data.stats.distance)
      }
    }

    // average team data and find range
    for (const lane in laneValues) {
      const teamSum = laneValues[lane].distances.reduce((a,b) => {return a + b},0)
      const teamAvg = teamSum / laneValues[lane].distances.length
      laneValues[lane].teamDistance = teamAvg
      if ((maxDistance === null) || teamAvg > maxDistance)
        maxDistance = teamAvg
      if ((minDistance === null) || teamAvg < minDistance)
        minDistance = teamAvg
    }
    minDistance = Math.floor(minDistance/100)*100

    // sort by lane and build chart dataset
    var chartData = {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1
      }]
    }
    const availableLanes = Object.keys(laneValues)
    availableLanes.sort()
    for (const laneNumber of availableLanes) {
      const thisLane = laneValues[laneNumber]
      chartData.labels.push('Lane '+laneNumber)
      chartData.datasets[0].data.push([minDistance,thisLane.teamDistance])
      chartData.datasets[0].backgroundColor.push(`rgba(${thisLane.color.r},${thisLane.color.g},${thisLane.color.b},0.5)`)
      chartData.datasets[0].borderColor.push(`rgba(255,255,255,0.7)`)
    }

    this.setState({chartData: chartData, lanesWithData: availableLanes})

  }

  formatTimeValue(value) {
    return `${(value < 10 ? `0${value}` : value)}`
  }

  shouldComponentUpdate(nextProps, nextState) {
    /*
  	const { eventData } = this.props
  	const boats = eventData.boats;

  	if (nextState.dataUpdates % boats.length !== 0) {
  	  return false;
  	}
    */
  	return true;

  }

  participantPlaceholder(athlete,boat) {
    return(
      <div key={"participantPlaceholder_"+athlete.name+athlete.seat} style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '1px solid gray', backgroundColor: 'rgba(0,0,0,0.5)'}}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: '20px'}}>
          <div style={{fontSize: 24, marginBottom: '10px'}}>{athlete.name}</div>
          <img src={boat.oarUrl} alt="oar" height="30px"/>
          <div style={{fontSize: 14}}>{boat.organization}</div>
        </div>
      </div>
    )
  }

  render() {
    const { participants,room,focusParticipant, focusData, showBio, showChart, showAnnouncers, timer, localParticipantRole, lanesWithData } = this.state
    const { eventData } = this.props
    const boats = eventData.boats;

    let participantLayout = []
    let moderators = []
    let producers = []

    const spectator = localParticipantRole === "spectator"
    if (localParticipantRole === "moderator")
        moderators.push(room.localParticipant)
    if (localParticipantRole === "producer")
      producers.push(room.localParticipant)

    // map remote video participants
    for (const participant of participants.values()) {
      let identity = JSON.parse(participant.identity)
      if (identity.role === "moderator") {
        moderators.push(participant)
      } else if (identity.role === "producer") {
        producers.push(participant)
      } else if (identity.role === "athlete") {
        if (!participantLayout[identity.lane])
          participantLayout[identity.lane] = {}
        participantLayout[identity.lane][identity.seat] = participant
      }
    }

    // normalize event data
    const mappedEventData = eventData.boats.reduce((acc,boat) => {
      const athletes = boat.athletes.reduce((acc,athlete) => {
        return {
          ...acc,
          [athlete.seat]: athlete
        }
      },{})
      const normalizedBoat = {
        ...boat,
        athletes
      }
      return {
        ...acc,
        [boat.lane]: normalizedBoat
      }
    },{})

    // determine number of cols/rows for layout grid
    const numTeams = Object.keys(mappedEventData).length
    let numCols = 2
    if (numTeams === 3)
      numCols = 3
    if (numTeams > 3)
      numCols = (numTeams % 2 === 0) ? (numTeams/2) : Math.ceil(numTeams/2)
    let numRows = numTeams > 3 ? 2 : 1

    if (numTeams > 10) {
      numCols = 5
      numRows = Math.ceil(numTeams/5)
    }


    // determine number of cols/rows for team grid
    const numTeamCols = (eventData.seats % 2 === 0) ? (eventData.seats/2) : Math.ceil(eventData.seats/2)
    const numTeamRows = eventData.seats > 1 ? 2 : 1

    const allAthletes = boats.map((item, i) => {
      return (
        <AllAthletes style={{backgroundColor: 'black'}} data={item.athletes} callback={this.displayAthlete} organization={item.organization} boatId={item.boatId} key={'list_'+item.boatId}/>
      )
    })

    const moderatorVideos = moderators.map(moderator => {
      return <ParticipantVideo width={'200px'} height={'150px'} showAnnouncers={showAnnouncers} hidden={false} key={'participantVideo_'+moderator.sid} participant={moderator} isLocalParticipant={room.localParticipant.sid === moderator.sid} dataCallback={room.localParticipant.sid === moderator.sid ? null : this.moderatorControlCallback} />
    })

    const producerVideos = producers.map(producer => {
      return <ParticipantVideo hidden={true} key={'participantVideo_'+producer.sid} participant={producer} isLocalParticipant={room.localParticipant.sid === producer.sid} dataCallback={room.localParticipant.sid === producer.sid ? null : this.moderatorControlCallback} />
    })

    const lanes = Object.values(mappedEventData).map(lane => {
      const laneNumber = lane.lane
      return (
        <div key={'lane_'+laneNumber} style={{display: 'grid', gridTemplateColumns: `repeat(${numTeamCols},1fr)`, gridTemplateRows: `repeat(${numTeamRows},1fr)`, border: '1px solid gray'}}>
          {Object.values(lane.athletes).map(athlete => {
            if (participantLayout[laneNumber] && participantLayout[laneNumber][athlete.seat])
              return (<ParticipantVideo boat={lane} onClick={() => this.setFocusParticipant(participantLayout[laneNumber][athlete.seat])} width={'100%'} height={'100%'} hidden={false} key={'participantVideo_'+athlete.athleteId} participant={participantLayout[laneNumber][athlete.seat]} moderatorAVControl={localParticipantRole === "spectator" ? null : this.moderatorAVControl} dataCallback={this.updateErgData} />)
            else
              return (this.participantPlaceholder(athlete,lane))
            })}
        </div>
      )
    })

    return(
      <div style={{width: '100%', height: '100%', minHeight: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{width:'50%'}}>
          <Modal show={this.state.showRaceModal} contentLabel="Race Format">
            <Modal.Header>Race settings</Modal.Header>
            <Modal.Body>
            <p/>
            <div className="radio">
              <label>
                <input name="format" type="radio" value="1" onChange={this.handleRaceFormatChange.bind(this)} checked={timer.race.direction===1} />
                Meters
              </label>
            </div>
            <div className="radio">
              <label>
                <input name="format" type="radio" value="-1" onChange={this.handleRaceFormatChange.bind(this)} checked={timer.race.direction===-1} />
                Time
              </label>
            </div>
            <p/>
            Race Length (In seconds or meters) <input type="text" pattern="[0-9]*" onChange={this.handleRaceLengthChange.bind(this)} defaultValue={timer.race.length} />
            <p/>
            Countdown time (In seconds) <input type="text" pattern="[0-9]*" onChange={this.handlePreludeLengthChange.bind(this)} defaultValue={timer.prelude.length} />
            <p/>
            <button onClick={this.handleCloseRaceModal}>Close Modal</button>
            </Modal.Body>
          </Modal>
        </div>
        <div style={{width:'50%'}}>
        <Modal center show={this.state.showAthleteModal}>
          <Modal.Header>Athletes</Modal.Header>
          <Modal.Body>

            {allAthletes}

            <button onClick={this.handleCloseAthleteModal}>Close Modal</button>
          </Modal.Body>
        </Modal>
      </div>


      { !spectator &&
    	  <Toolbar eventData={eventData}
      		toggleDisplayBio={this.toggleDisplayBio} showBio={ showBio }
      		toggleDisplayChart={this.toggleDisplayChart} showChart={ showChart }
      		toggleDisplayAnnouncers={this.toggleDisplayAnnouncers} showAnnouncers={showAnnouncers}
      		emitSocketAction={this.emitSocketAction}
      		timerToggle={this.timerToggle} timer={ timer }
      		showRaceModal={ this.handleOpenRaceModal }
      		showAthleteModal={ this.handleOpenAthleteModal }
          />
      }
      { /* header / moderator window */ }
        <div style={{display: 'flex', flexDirection: 'row', width: '98%', marginTop: '15px', marginBottom: '15px', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <img src={this.state.logo} alt="RegattaCentral" style={{width: 175, height: 90, objectFit: 'contain'}} />
            <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'start', marginLeft: 20}}>
              <span style={{fontSize: 24}}>{eventData.regattaName}</span>
              <span style={{fontSize: 18}}>{eventData.raceName}</span>
              { localParticipantRole !== "spectator" &&
                <input style={{width: 100, marginTop: 10}} type="button" value="Disconnect" onClick={this.props.disconnect} />
              }
            </div>
            { /*
            <div>
              <img src={RCStackLogo} alt="RegattaCentral" style={{paddingLeft: '5rem', paddingTop: '1rem'}} />
            </div>
            */ }
          </div>

          <div style={{display: 'flex', flexDirection: 'row'}}>

  		      <div style={{display: 'flex', alignItems: 'center', padding: '10px', border: '2px solid ' + timer.color , color: timer.color, fontSize: '5rem', marginRight: '15px'}}>
  		        {timer.display}
  		      </div>

            {moderatorVideos}
            {producerVideos}
          </div>
        </div>


        { !focusParticipant && !focusData &&
          <div style={{display: 'grid', gridTemplateColumns: showChart ? '1fr 500px' : '1fr', width: '100%', height: '100%' }}>
            <div style={{display: 'grid', gridTemplateColumns: `repeat(${numCols},1fr)`, gridTemplateRows: `repeat(${numRows},1fr)`, gap: '10px', height: '100%', width: '100%', padding: '10px', boxSizing: 'border-box'}}>
              {lanes}
            </div>
            {showChart &&
            <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-end', marginRight: '10px'}}>
              <div style={{position: 'relative', width: '100%', height: '70vh'}}>
              <Bar
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  legend: {
                    display: false
                  },
                  scales: {
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: "meters",
                        fontColor: '#FFF',
                        fontSize: 20
                      },
                      ticks: {
                        fontColor: '#FFF'
                      }
                    }],
                    xAxes: [{
                      ticks: {
                        fontColor: '#FFF'
                      }
                    }]
                  }}}

                data={this.state.chartData}
              />
              </div>
              <div style={{display: 'flex', flexDirection: 'row', height: '30px', marginLeft: '65px', marginBottom: '20px'}}>
              {
                lanesWithData.map(lane => {
                  return (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1}}><img style={{paddingRight: '10px'}} alt="Team Flag" src={mappedEventData[lane].oarUrl} height={'30px'} /></div>
                  )
                })
              }
              </div>
            </div>
            }
          </div>
        }

        { focusParticipant &&
          <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', height: '100%', maxHeight: '800px', justifyContent: 'center', alignItems: 'center'}}>
    	      <ParticipantVideo boat={mappedEventData[JSON.parse(focusParticipant.identity).lane]} width={'70%'} height={'90%'} onClick={() => this.setFocusParticipant(null)} hidden={false} key={'participantVideo_'+focusParticipant.sid} participant={focusParticipant} moderatorAVControl={localParticipantRole === "spectator" ? null : this.moderatorAVControl} />
          </div>
        }
        { /*focusData &&

          <div style={{width: '80%', alignItems: 'center'}}>
            <div>
              <CanvasJSChart options = {this.state.bar} onRef={ref => this.chart = ref} ref={this.chartContainerRef} onClick={() => this.setFocusData(null)} />
            </div>
          </div>
          */
        }

      </div>
    )
  }

}

export default ModeratorRoom