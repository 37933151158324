import React, { Component } from 'react';
import ParticipantVideo from '../ParticipantVideo'
import TeamView from '../TeamView'
import TimerBeep from '../../assets/images/beep-08b.wav'
import TimerBeep2 from '../../assets/images/beep-02.wav'

const FAKE_BLE_DATA = false

const PARTICIPANT_WIDTH = '320px'
const PARTICIPANT_HEIGHT = '240px'

const MODERATOR_WIDTH = '120px'
const MODERATOR_HEIGHT = '100px'

const IS_CHROME = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

var ergometer = require('../../libs/ergometer.js').ergometer();

class ParticipantRoom extends Component {

  constructor(props) {
    super(props)

    const localParticipantIdentity = JSON.parse(this.props.room.localParticipant.identity)
    this.state = {
      room: this.props.room,
      participants: this.props.room.participants,
      beep: new Audio(TimerBeep),
      beep2: new Audio(TimerBeep2),
      timer: null,
      BLEConnected: false,
      BLEData: {
        distance: 0,
        elapsedTime: 0,
        strokeRate: 0,
        pace: 0,
        lane: localParticipantIdentity.lane,
        seat: localParticipantIdentity.seat,
      }
    }
    this.fakeBLETimer = null
    this.scanTimer = null

    this.moderatorControlCallback = this.moderatorControlCallback.bind(this)

    this.pairBLE = this.pairBLE.bind(this)
    this.disconnectBLE = this.disconnectBLE.bind(this)
    this.resetRace = this.resetRace.bind(this)
    this.disconnect = this.disconnect.bind(this)
    this.fakeScanStart = this.fakeScanStart.bind(this)
    if (IS_CHROME) {
        this.performanceMonitor = new ergometer.PerformanceMonitor()
        this.performanceMonitor.enableMultiplexNotification()
        this.performanceMonitor.rowingGeneralStatusEvent.sub(this, this.onRowingGeneralStatus)
        this.performanceMonitor.rowingAdditionalStatus1Event.sub(this, this.onRowingAdditionalStatus1)
    }

  }

  componentDidMount() {

    const { room } = this.state

    // console current room state
    room.participants.forEach(participant => {
      let identity = JSON.parse(participant.identity)
      console.log("Already in Room: " + identity.name + " - role: " + identity.role);
    });

    // set up event listeners for room

    // Participant joining room
    room.on('participantConnected', participant => {
      let identity = JSON.parse(participant.identity)
      console.log("Joining: " + identity.name + " - role: " + identity.role);
      this.setState({participants: room.participants})
    });

    room.on('participantDisconnected', participant => {
      console.log('participantDisconnected')
      this.setState({participants: room.participants})
    })

    // console output just to listen/debug room events
    /*
    room.on('trackPublished', (publication, participant) => {
      console.log('trackPublished')
      console.log(publication)
    })

    room.on('trackUnpublished', (publication, participant) => {
      console.log('trackUnpublished')
      console.log(publication)
    })

    room.on('trackSubscribed', (track,publication,participant) => {
      console.log('trackSubscribed')
      console.log(publication)
    })

    room.on('trackUnsubscribed', (track, publication, participant) => {
      let identity = JSON.parse(participant.identity)
      console.log(identity.name + ' removed track: ' + track.kind);
    });
    */
  }

  fakeScanStart() {
	this.scanTimer = setTimeout(() => {
	  this.startScan()
	  this.fakeScanStart()
	},3000)

  }

  moderatorControlCallback(data) {

    const dataObj = JSON.parse(data)

    const { participants,room, beep, beep2 } = this.state

    // verify source is moderator
    if (dataObj.source) {
      const participant = dataObj.source ? participants.get(dataObj.source) : '{}'
      const identity = JSON.parse(participant.identity)
      if (identity.role !== "moderator" && identity.role !== "producer")
        return
    }

    if (dataObj.action) {

      if (dataObj.action === "setTimer") {
      	const timer = dataObj.data
      	if(timer.beep)
        	  beep.play()
      	if(timer.beep2)
      	  beep2.play()
        this.setState({timer:timer})
      }
      if (dataObj.action === "muteParticipantAudio") {
        if (dataObj.data && dataObj.data === room.localParticipant.sid) {
          const tracks = room.localParticipant.tracks
          tracks.forEach(publication => {
            if (publication.kind && publication.kind === "audio") {
              publication.track.disable()
            }
          })
        }
      }
      if (dataObj.action === "unmuteParticipantAudio") {
        if (dataObj.data && dataObj.data === room.localParticipant.sid) {
          const tracks = room.localParticipant.tracks
          tracks.forEach(publication => {
            if (publication.kind && publication.kind === "audio") {
              publication.track.enable()
            }
          })
        }
      }
      if (dataObj.action === "muteParticipantVideo") {
        if (dataObj.data && dataObj.data === room.localParticipant.sid) {
          const tracks = room.localParticipant.tracks
          tracks.forEach(publication => {
            if (publication.kind && publication.kind === "video") {
              publication.track.disable()
            }
          })
        }
      }
      if (dataObj.action === "unmuteParticipantVideo") {
        if (dataObj.data && dataObj.data === room.localParticipant.sid) {
          const tracks = room.localParticipant.tracks
          tracks.forEach(publication => {
            if (publication.kind && publication.kind === "video") {
              publication.track.enable()
            }
          })
        }
      }

    } // end participant actions

  }

  pairBLE() {
	  	if (this.state.BLEConnected) {
	  	  this.disconnectBLE()
	  	} else {
	  	  this.startScan()
	  	}
	  }

  disconnectBLE() {
  	this.performanceMonitor.disconnect()
    clearTimeout(this.fakeBLETimer)
    this.setState({BLEConnected: false})
  }

  disconnect() {
    if (IS_CHROME)
      this.disconnectBLE()
  	this.props.disconnect()
  }

  resetRace() {
  	console.log('reset race')
  }

  broadcastData = function (data) {
    const { room } = this.state

    const tracks = room.localParticipant.tracks

	  const messageObj = {
      action: 'stats',
      stats: data
    }

    tracks.forEach(publication => {
      if (publication.kind && publication.kind === "data") {
        publication.track.send(JSON.stringify({...messageObj,source: room.localParticipant.sid}))
      }
    })

  }

  onRowingGeneralStatus(data) {
	if (data.distance === 0) return
    const newBLEData = {
      ...this.state.BLEData,
      distance: data.distance,
      elapsedTime: data.elapsedTime
    }
    this.setState({BLEData: newBLEData},() => {
      this.broadcastData(this.state.BLEData)
    })
  }

  onRowingAdditionalStatus1(data) {
	if (data.currentPace === 0) return
    const newBLEData = {
      ...this.state.BLEData,
      elapsedTime: data.elapsedTime,
      strokeRate: data.strokeRate,
      pace: data.currentPace
    }
    this.setState({BLEData: newBLEData})
  }

  startScan() {
	    if (FAKE_BLE_DATA) {
	      this.setState({BLEConnected: true},() => {
	        this.onRowingAdditionalStatus1({
	          elapsedTime: 0,
	          strokeRate: 28,
	          currentPace: 102700
	        })
	        this.fakeBLEStart()
	      })
	    } else {
	    	console.log('starting scan')
	      this.performanceMonitor.startScan((device) => {
	        //in web blue tooth the device selection is done by the user
	        //just return true to to accept the user selection
	    	  console.log(device)
	        this.setState({BLEConnected: true})
	        return true
	      });
	    }
	  }

  fakeBLEStart() {
    this.fakeBLETimer = setTimeout(() => {
      this.fakeBLEBroadcast()
      this.fakeBLEStart()
    },1000)
  }

  fakeBLEBroadcast() {
    this.onRowingGeneralStatus({
      distance: this.state.BLEData.distance + 3,
      elapsedTime: this.state.BLEData.elapsedTime + 1000
    })
  }

  participantPlaceholder(athlete,width=PARTICIPANT_WIDTH,height=PARTICIPANT_HEIGHT,showSeat=true) {
    return(
      <div style={{width: width, height: height, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '1px solid gray', backgroundColor: 'rgba(0,0,0,0.5)'}}>
        {showSeat && <span style={{fontSize: 14}}>Seat {athlete.seat}</span>}
        <span style={{fontSize: 14}}>{athlete.name}</span>
      </div>
    )
  }

  render() {

    const { participants, room, BLEConnected, BLEData, timer } = this.state
    const { eventData } = this.props

    // The twilio SDK doesn't emit events on the LocalDataTrack object, so we'll
    // pass BLE data down to the participant video via props
    const localDataObj = JSON.stringify({
      source: room.localParticipant.sid,
      action: 'stats',
      stats: BLEData
    })

    let participantLayout = {}
    let moderators = []
    let producers = []
    let hiddenAthletes = []
    const localParticipantIdentity = JSON.parse(room.localParticipant.identity)

    // map remote video participants
    for (const participant of participants.values()) {
      let identity = JSON.parse(participant.identity)
      if (identity.role === "moderator") {
        moderators.push(participant)
      } else if (identity.role === "producer") {
        producers.push(participant)
      } else if (identity.role === "athlete") {
        if (!participantLayout[identity.lane])
          participantLayout[identity.lane] = {}
        participantLayout[identity.lane][identity.seat] = participant

        if (identity.lane !== localParticipantIdentity.lane)
          hiddenAthletes.push(participant)
      }
    }

    // normalize event data
    const mappedEventData = eventData.boats.reduce((acc,boat) => {
      const athletes = boat.athletes.reduce((acc,athlete) => {
        return {
          ...acc,
          [athlete.seat]: athlete
        }
      },{})
      const normalizedBoat = {
        ...boat,
        athletes
      }
      return {
        ...acc,
        [boat.lane]: normalizedBoat
      }
    },{})

    console.log(hiddenAthletes)
    const moderatorVideos = moderators.map(moderator => {
      return <ParticipantVideo width={MODERATOR_WIDTH} height={MODERATOR_HEIGHT} key={'participantVideo_'+moderator.sid} hidden={false} participant={moderator} isLocalParticipant={room.localParticipant.sid === moderator.sid} dataCallback={room.localParticipant.sid === moderator.sid ? null : this.moderatorControlCallback} />
    })
    const producerVideos = producers.map(producer => {
      return <ParticipantVideo width={MODERATOR_WIDTH} height={MODERATOR_HEIGHT} key={'participantVideo_'+producer.sid} hidden={true} participant={producer} isLocalParticipant={room.localParticipant.sid === producer.sid} dataCallback={room.localParticipant.sid === producer.sid ? null : this.moderatorControlCallback} />
    })
    const hiddenAthleteVideos = hiddenAthletes.map(athlete => {
      return <ParticipantVideo width={MODERATOR_WIDTH} height={MODERATOR_HEIGHT} key={'participantVideo_'+athlete.sid} hidden={false} audioOnly={true} participant={athlete} isLocalParticipant={room.localParticipant.sid === athlete.sid} dataCallback={room.localParticipant.sid === athlete.sid ? null : this.moderatorControlCallback} />
    })

    return(
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1}}>


        { /* header / moderator window */ }
        <div style={{display: 'flex', flexDirection: 'column', marginTop: 10, alignItems: 'center', width: '100%'}}>

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: PARTICIPANT_WIDTH}}>
            <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'flex-start'}}>
              <span style={{textAlign: 'left', fontSize: 18}}>{eventData.regattaName}</span>
              <span style={{fontSize: 14}}>{eventData.raceName}</span>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'flex-start'}}>
              <input style={{width: 100, marginTop: 10}} type="button" value="Disconnect" onClick={this.disconnect} />
              { IS_CHROME &&
            	  <div>
                  <input style={{width: 100, marginTop: 10}} type="button" value={BLEConnected ? 'Stop PM5' : 'Pair PM5'} onClick={this.pairBLE} />
                  </div>
              }
            </div>
          </div>

          <div style={{marginTop: 10, display: 'flex', alignItems: 'center', flexDirection: 'column', width: '95%'}}>

            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 10, justifyContent: 'center'}}>
              {moderatorVideos}
              {producerVideos}
            </div>

            <div style={{width: '100%', height: '30vh', maxWidth: '800px', maxHeight: '600px'}}>
              <ParticipantVideo boat={mappedEventData[localParticipantIdentity.lane]} width={'100%'} height={'100%'} hidden={false} key={'participantVideo_'+room.localParticipant.sid} participant={room.localParticipant} isLocalParticipant={true} localData={localDataObj} />
            </div>

            { timer &&
              <div style={{width: '100%', height: '20vh', maxWidth: '800px', maxHeight: '600px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px solid ' + timer.color , color: timer.color, fontSize: '5.5em', padding: '10px'}}>
                {timer.display}
              </div>
            }

          </div>



          {eventData.seats >= 4 &&
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, justifyContent: 'center'}}>
              <TeamView
                room={room}
                participant={room.localParticipant}
                eventData={eventData}
              />
            </div>
          }
          {/*
            // this block was for 1v1 races to show just the 2 competitors
            eventData.seats === 0 &&
            <Fragment>
            <span style={{fontSize: 14}}>vs</span>
            { localParticipantIdentity.lane === 1 &&
              <Fragment>
              { participantLayout[2]
                ? <ParticipantVideo boat={mappedEventData[localParticipantIdentity.lane]} width={PARTICIPANT_WIDTH} height={PARTICIPANT_HEIGHT} hidden={false} key={'participantVideo_'+participantLayout[2][1].sid} participant={participantLayout[2][1]} />
                : this.participantPlaceholder(mappedEventData[2].athletes[1],PARTICIPANT_WIDTH,PARTICIPANT_HEIGHT,false)
              }
              </Fragment>
            }
            { localParticipantIdentity.lane === 2 &&
              <Fragment>
              { participantLayout[1]
                ? <ParticipantVideo boat={mappedEventData[localParticipantIdentity.lane]} width={PARTICIPANT_WIDTH} height={PARTICIPANT_HEIGHT} hidden={false} key={'participantVideo_'+participantLayout[1][1].sid} participant={participantLayout[1][1]} />
                : this.participantPlaceholder(mappedEventData[1].athletes[1],PARTICIPANT_WIDTH,PARTICIPANT_HEIGHT,false)
              }
              </Fragment>
            }
            </Fragment>
          */}

          {/*eventData.seats >= 4 &&
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 10, maxWidth: PARTICIPANT_WIDTH}}>
            <img src={mappedEventData[localParticipantIdentity.lane].oarUrl} alt="Team Flag" /><span style={{fontSize: 14, marginLeft: 10}}>{mappedEventData[localParticipantIdentity.lane].organization}</span>
          </div>
          */}

          <div>
          {hiddenAthleteVideos}
          </div>

        </div>


      </div>
    )
  }

}

export default ParticipantRoom