import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment'
import { ReactComponent as CameraOff } from '../../assets/icons/videocam_off-24px.svg'
import { ReactComponent as CameraOn } from '../../assets/icons/videocam-24px.svg'
import { ReactComponent as MicOff } from '../../assets/icons/volume_off-24px.svg'
import { ReactComponent as MicOn } from '../../assets/icons/volume_up-24px.svg'

class ParticipantVideo extends Component {

  constructor(props) {
    super(props)
    this.mediaContainerRef = React.createRef()
    this.dataContainerRef = React.createRef()
    this.state = {
      audioMuted: false,
      videoMuted: false,
    }
    this.toggleAudioMute = this.toggleAudioMute.bind(this)
    this.toggleVideoMute = this.toggleVideoMute.bind(this)
    this.dataTrackListener = this.dataTrackListener.bind(this)
  }

  componentDidMount() {

    const { participant } = this.props
    const trackPublications = participant.tracks
    const mediaContainer = this.mediaContainerRef.current

    // set up listeners for existing tracks
    trackPublications.forEach(publication => {

      if (publication.isSubscribed === false) {
        publication.on('subscribed', track => {
          if (publication.kind === "data") {
            publication.track.on('message',this.dataTrackListener)
          } else {
            mediaContainer.appendChild(publication.track.attach())
            publication.track.on('enabled',() => {
              if (publication.kind === "audio")
                this.setState({audioMuted: false})
              if (publication.kind === "video")
                this.setState({videoMuted: false})
            })
            publication.track.on('disabled',() => {
              if (publication.kind === "audio")
                this.setState({audioMuted: true})
              if (publication.kind === "video")
                this.setState({videoMuted: true})
            })
          }
        });
      } else {
        if (publication.kind === "data") {
          publication.track.on('message',this.dataTrackListener)
        } else {
          mediaContainer.appendChild(publication.track.attach())
          publication.track.on('enabled',() => {
            if (publication.kind === "audio")
              this.setState({audioMuted: false})
            if (publication.kind === "video")
              this.setState({videoMuted: false})
          })
          publication.track.on('disabled',() => {
            if (publication.kind === "audio")
              this.setState({audioMuted: true})
            if (publication.kind === "video")
              this.setState({videoMuted: true})
          })
        }
      }

      // set initial state for UI track control
      if (publication.kind === "audio")
        this.setState({audioMuted: !publication.isTrackEnabled})
      if (publication.kind === "video")
        this.setState({videoMuted: !publication.isTrackEnabled})

    })

    // listen to new tracks as they're published
    participant.on('trackPublished', publication => {
      if (publication.isSubscribed === false) {
        publication.on('subscribed', track => {
          if (publication.kind === "data") {
            publication.track.on('message',this.dataTrackListener)
          }
        })
      } else {
        if (publication.kind === "data") {
          publication.track.on('message',this.dataTrackListener)
        }
      }
    })

  }

  componentDidUpdate(prevProps) {
    // if localData prop is supplied, treat like we're receiving a socket message
    // this is a workaround for a lack of LocalDataTrack events
    if (this.props.localData && (this.props.localData !== prevProps.localData)) {
      this.dataTrackListener(this.props.localData)
    }
  }

  dataTrackListener(data) {
    // this listener is configured to grab the data emitted for THIS PARTICIPANT
    // good for user-specific data, not room broadcast (i.e. erg stream data)

  	const dataObj = JSON.parse(data)

  	if (this.props.participant.sid === dataObj.source) {

      if (dataObj.action === "stats") {
    		const stats = dataObj.stats

    		const dataContainer = this.dataContainerRef.current

    		if (null !== dataContainer && undefined !== stats) {
    		  let formatString = 'm:ss'
    		  if (stats.elapsedTime > 3600000) {
    			formatString = 'h:mm:ss'
    		  }
    		  let statString = '<span> '
    		  if (stats.distance > 0) statString += stats.distance + 'm '
    		  if (stats.strokeRate > 0) statString += '<span>' + stats.strokeRate + 's/m</span> '
    		  if (stats.pace > 0) statString += moment.utc(stats.pace).format("m:ss") + '/500m  '
    		  if (stats.elapsedTime > 0) statString += moment.utc(stats.elapsedTime).format(formatString) + ' '
    		  statString += '</span>'
    		  dataContainer.innerHTML = statString;
    		} else if (null !== dataContainer) {
    			dataContainer.innerHTML = 'No Erg Data Available'
    		}
      }

  	}

    // intercept for optional data callback to bring the data up to the room component
    if (this.props.dataCallback)
      this.props.dataCallback(data)
  }

  toggleAudioMute() {
    if (this.props.moderatorAVControl) {
      // pass up to moderator control
      this.props.moderatorAVControl({audioMuted: !this.state.audioMuted, participant: this.props.participant.sid})
    } else {
      // local control
      const tracks = this.props.participant.tracks
      tracks.forEach(publication => {
        if (publication.kind && publication.kind === "audio") {
          if (this.state.audioMuted)
            publication.track.enable()
          else
            publication.track.disable()
        }
      })
      this.setState({audioMuted: !this.state.audioMuted})
    }
  }

  toggleVideoMute() {
    if (this.props.moderatorAVControl) {
      // pass up to moderator control
      this.props.moderatorAVControl({videoMuted: !this.state.videoMuted, participant: this.props.participant.sid})
    } else {
      const tracks = this.props.participant.tracks
      tracks.forEach(publication => {
        if (publication.kind && publication.kind === "video") {
          if (this.state.videoMuted)
            publication.track.enable()
          else
            publication.track.disable()
        }
      })
      this.setState({videoMuted: !this.state.videoMuted})
    }
  }

  render() {

    const { participant, width, height, hidden, audioOnly, showAnnouncers, boat } = this.props
    const { videoMuted, audioMuted } = this.state
    const identity = JSON.parse(participant.identity)

    if (hidden)   // ie this is the producer
    	return null

    if (audioOnly)
      return (
        <div style={{display: 'none'}} ref={this.mediaContainerRef} />
      )

    const displayAVControls = (this.props.isLocalParticipant === true || this.props.moderatorAVControl)
    const audioControl = audioMuted
                  ? <MicOff alt="Click to un-mute" onClick={this.toggleAudioMute} style={{fill: 'red', zIndex: 10, cursor: 'pointer'}} />
                  : <MicOn alt="Click to mute" onClick={this.toggleAudioMute} style={{fill: 'white', zIndex: 10, cursor: 'pointer'}} />
    const videoControl = videoMuted
                  ? <CameraOff onClick={this.toggleVideoMute} style={{fill: 'red', zIndex: 10, cursor: 'pointer'}} />
                  : <CameraOn onClick={this.toggleVideoMute} style={{fill: 'white', zIndex: 10, cursor: 'pointer'}} />
    return(

      <ParticipantVideoComponent width={width} height={height} display={!showAnnouncers ? 'none' : 'flex'}>
        { boat &&
          <TitleContainer height={'45px'}>
            <div style={{display: 'flex', flexDirection: 'row', width: '98%'}}>
              <div style={{marginRight:'1rem'}}>
          	    <img src={boat.oarUrl} alt="foobar" height="30px"/>
              </div>
              <div>
        	    {identity.name} <br/>
        	    {boat.organization}
              </div>
            </div>
          </TitleContainer>
        }
        { !boat &&
          <TitleContainer height={'25px'}>
            <div style={{display: 'flex', flexDirection: 'row', width: '98%'}}>
    	      {identity.name}
            </div>
          </TitleContainer>
        }

        <StyledMediaContainer videoMuted={videoMuted} width={width} height={height} ref={this.mediaContainerRef} onClick={this.props.onClick ? this.props.onClick : null} bottomBar={(displayAVControls || (identity.role === "athlete" && boat)) ? '-40px' : 0} topBar={boat ? '-45px' : '-25px'}/>

        { videoMuted &&
          <MutedVideoPlaceholder width={width} height={height}>
            <CameraOff style={{marginTop: '60px', fill: 'rgba(255,255,255,0.1', transform: 'scale(3)'}} />
            <span>(video not available)</span>
          </MutedVideoPlaceholder>
        }
        { (displayAVControls || (identity.role === "athlete" && boat)) &&
          <div style={{height: '40px', padding: '0 5px 0 5px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', zIndex: 10, backgroundColor: 'rgba(0,0,0,0.5)' }}>
        	{ boat &&
        	  <StyledDataContainer width={width} height={height} ref={this.dataContainerRef} >
        	    No Erg Data Available
        	  </StyledDataContainer>
        	}
            { displayAVControls &&
              <div style={{display: 'flex', flexDirection: 'row'}}>
                { videoControl }
                { audioControl }
              </div>
            }
          </div>
        }
      </ParticipantVideoComponent>
    )
  }

}

const ParticipantVideoComponent = styled.div`
  width: ${props => props.width ? props.width : '320px'};
  height: ${props => props.height ? props.height : '240px'};
  max-height: inherit;
  max-width: inherit;
  min-width: inherit;
  min-height: inherit;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

const StyledMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${props => props.topBar} 0 ${props => props.bottomBar} 0;
  flex: 1;
  max-height: inherit;
  max-width: inherit;
  min-width: inherit;
  min-height: inherit;
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  overflow: hidden;
  position: relative;

  video {
    display: ${props => props.videoMuted ? 'none' : 'block'};
    object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
  }
`;

const StyledDataContainer = styled.div`
  text-align: left;
  margin-left: 1rem;
  font-size: 12px;
  color: white;
`;

const MutedVideoPlaceholder = styled.div`
  width: inherit;
  height: inherit;

  background-color: #282c34;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    font-size: 12px;
    color: white;
    margin-top: 20px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${props => props.height};
  z-index: 10;
  padding: 0 0 0 5px;
  background-color: rgba(0,0,0,0.5);
  color: white;
  font-size: 12px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
`;


export default ParticipantVideo