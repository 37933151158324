import React, { Component, Fragment } from 'react';

class AllAthletes extends Component {
  
  render() {
    const liCls = "p-3 border text-gray-700"

    const { data, organization, boatId } = this.props

    return (
	  <Fragment>
        <ul style={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
          <li key={boatId} style={{display: 'flex', flexDirection: 'row', alignItems: 'left'}}>
            {organization}
          </li>
  	      <ul style={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
          {data.map((item, i) => (
    	    <li key={item.athleteId} className={liCls} onClick={() => this.props.callback(item.athleteId)} style={{display: 'flex', flexDirection: 'row', alignItems: 'left'}}>
    	      {item.name} - Token: {item.token}
           </li>
          ))}
          </ul>
        </ul>
      </Fragment>
    )
  } 
}
export default AllAthletes