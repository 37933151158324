import React, { Component, } from 'react';
import axios from 'axios';
import Video, { LocalDataTrack } from 'twilio-video';
import ModeratorRoom from './components/ModeratorRoom'
import ParticipantRoom from './components/ParticipantRoom'
import RegattaLogo from './assets/images/Regatta_Central_Logo_STACK_Rev.png'
import BackgroundImage from './assets/images/rowing_bg.jpg'
import './App.css';
import { parse as queryParse } from 'query-string'

const server = 'https://live-api.regattacentral.com'

class App extends Component {

  constructor(props) {

    super(props)
    this.errorContainer = React.createRef()
    this.state = {
      regattaToken: null,
      twilioToken: null,
      displayName: null,
      hasJoinedRoom: false,
      activeRoom: null,
      joining: false,
      races: null
    }
    this.startConnect = this.startConnect.bind(this)
    this.getRaceList = this.getRaceList.bind(this)
    this.joinRoom = this.joinRoom.bind(this)
    this.roomJoined = this.roomJoined.bind(this)
    this.leaveRoom = this.leaveRoom.bind(this)

  }

  componentDidMount() {
    // automatically attempt to join room if token is passed in URL
    const parsed = queryParse(window.location.search)

    let tokenState = {}
    for (const prop in parsed) {
      tokenState.regattaToken = prop
    }

    // if values were passed, attempt auto login
    if (tokenState.regattaToken) {
      this.setState(tokenState,() => {
        this.startConnect()
      })
    } else {
    	this.getRaceList()
    }

  }

  getRaceList() {
	const url = server + `/races`
	axios.get(url).then(results => {
	  if (results.data === "FAILED") {
	    console.log('failed to find races')
	  } else {
		console.log(results)
//	    this.setState({ races: results.data.races} );
	  }
	})
  }

  startConnect() {

    const errorContainer = this.errorContainer.current

    console.log("Fetching video service token...")
    this.setState({joining: true})

    const tokenURL = server+`/auth`

    const postObj = {
      token: this.state.regattaToken
    }
    if (this.state.spectator)
      postObj.spectator = true
    axios.post(tokenURL,postObj).then(results => {
      if (results.data === "DENIED") {
        console.log("Security Fail!")
        errorContainer.innerHTML = "There was a problem with your participant code. Please try again."
        this.setState({joining: false})
      } else {
        const { identity, token, eventData } = results.data;
        this.setState({ identity: JSON.parse(identity), eventData, twilioToken: token },() => this.joinRoom());
      }
    })

  }

  joinRoom() {
    const roomName = this.state.eventData.roomId

    console.log("Joining room '" + roomName + "'... as " + this.state.identity.role);

    if (this.state.identity.role === "spectator") {
      // spectator, don't activate media streaming
      console.log("SPECTATOR INIT")
      let connectOptions = {
        name: roomName,
        automaticSubscription: true,
        video: false,
        audio: false
      }
      Video.connect(this.state.twilioToken, connectOptions).then(this.roomJoined, error => {
        alert('Could not connect to video service: ' + error.message);
      })
    } else if (this.state.identity.role === "producer") {
        // producer, don't activate media streaming
        const dataTrack = new LocalDataTrack();
        let connectOptions = {
          name: roomName,
          automaticSubscription: true,
          video: false,
          audio: false,
          tracks: [dataTrack],
        }
        Video.connect(this.state.twilioToken, connectOptions).then(this.roomJoined, error => {
          alert('Could not connect to video service: ' + error.message);
        })

    } else {
      // not spectator nor producer
      navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      }).then((mediaStream) => {
        //const constraints = {width: 400, height: 200}
        //const track = mediaStream.getVideoTracks()[0];
        //track.applyConstraints(constraints)
        let mediaTracks = mediaStream.getTracks()
        const dataTrack = new LocalDataTrack();
        let connectOptions = {
          name: roomName,
          automaticSubscription: true,
          tracks: [...mediaTracks,dataTrack],
        };
        return Video.connect(this.state.twilioToken, connectOptions)
      }).then(this.roomJoined, error => {
        alert('Could not connect to video service: ' + error.message);
      })
    }

  }

  roomJoined(room) {

    let identity = this.state.identity
    console.log(`Joined as ${identity.role === "moderator" || identity.role === "producer" || identity.role === "spectator" ? identity.role : identity.name}`);

    this.setState({
      activeRoom: room,
      hasJoinedRoom: true,
      joining: false
    });

  }

  leaveRoom() {
    console.log("Disconnecting...")
    this.state.activeRoom.localParticipant.tracks.forEach(publication => {
      if ((publication.kind === 'video') || (publication.kind === 'audio'))
        publication.track.stop()
    })
    this.state.activeRoom.disconnect()
    this.setState({ hasJoinedRoom: false })
  }



  pageLoaded() {
      var _this = this;
      document.getElementById('devices').change(function () {
          _this.performanceMonitor.connectToDevice(this.value);
      });
  }

  render() {

	const races = this.state.races
    const identity = this.state.identity ? this.state.identity : null

    let upcomingRaces = null
    let previousRaces = null

    if (races) {
      previousRaces = races.previous.map(race => {
        return (<a key={race.url} href={race.url}>{race.title}</a>)
      })
      upcomingRaces = races.upcoming.map(race => {
	    return (<a key={race.url} href={race.url}>{race.title}</a>)
	  })
    }

    return (
      <div className="App" style={{backgroundImage: "url("+BackgroundImage+")", backgroundSize: 'cover', backgroundBlendMode: 'luminosity'}}>
      <div className="App" style={{backgroundColor: 'rgba(40,44,52,0.9)'}}>


          { this.state.hasJoinedRoom === false
            ? <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
          	    <div className="App-header" style={{width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '1rem'}}>
          	      {previousRaces}
          	    </div>
                <div className="App-header" style={{width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <a
                  href="https://www.regattacentral.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={RegattaLogo} alt="logo" />
                </a>


                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end',  marginTop: 20, fontSize: 12, padding: 15}}>

                  <span>Participant Code: <input type="text" disabled={this.state.joining} onChange={(e) => this.setState({regattaToken: e.target.value})} /></span>

                </div>

                <input style={{marginTop: 20}} type="button" disabled={this.state.joining} value={this.state.joining ? "Connecting..." : "Connect"} onClick={this.startConnect} />

                <span ref={this.errorContainer} style={{marginTop: 20, fontSize: 16, fontWeight: 'bold'}} />
                </div>
          	    <div className="App-header" style={{width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: '1rem'}}>
        	      {upcomingRaces}
        	    </div>
        	  </div>
            : (identity.role === "moderator" || identity.role === "spectator" || identity.role === "producer"
                ? <ModeratorRoom
                    room={this.state.activeRoom}
                    eventData={this.state.eventData}
                    disconnect={this.leaveRoom}
                  />
                : <ParticipantRoom
                    room={this.state.activeRoom}
                    eventData={this.state.eventData}
                    disconnect={this.leaveRoom}
                  />
              )

          }

      </div>
      </div>
    );
  }
}

export default App;
